import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import {
  IFixed,
  ILocalFile
} from "src/types/local-files";

import FieldTechnicalArticle from "../components/field-technical-article";
import { IFieldTechnicalArticle } from "../components/field-technical-article/field-technical-article";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import PageTopArticleHeading from "../components/page-top-article-heading";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import { DynamicImageSrcString } from "./utils/dynamicImage";

interface IProps {
  nodeOurProcessOverview: IOurProcessData;
}

interface IOurProcessData {
  created: string;
  changed: string;
  title: string;
  path: {
    alias: string;
  };
  field_meta_description: string;
  relationships: {
    field_mobile_hero_image: ILocalFile<IFixed>;
    field_hero_image: ILocalFile<IFixed>;
    field_technical_article: IFieldTechnicalArticle[];
    field_technical_article_2: IFieldTechnicalArticle[];
  };
}

/**
 * Our process
 */
const OurProcess: React.FC<{ data: IProps }> = ({ data }) => {
  const { width } = useWindowDimensions();
  const article = data.nodeOurProcessOverview;
  const rels = article.relationships;

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: article.title,
      path: article.path.alias
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  /**
   * Dynamic background image depending the devices width
   */
  const backgroundImg = React.useMemo(() => {
    if (width && width < 600) {
      return DynamicImageSrcString(rels.field_mobile_hero_image);
    }

    return DynamicImageSrcString(rels.field_hero_image);
  }, [
    rels.field_hero_image,
    rels.field_mobile_hero_image,
    width
  ]);

  return (
    <Layout>
      <>
        <HelmetWrapper
          title={article.title}
          description={article.field_meta_description}
          updatedAt={article.changed}
          canonicalPath={article.path.alias}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              backgroundImage={backgroundImg}
              overlayColor="black"
              height="small"
            >
              <PageTopArticleHeading
                className="top-content"
                type="basic"
                title={article.title}
              />
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {article.title}
          </h1>

          <OurProcessWrapper>
            <FieldTechnicalArticle
              type="basic"
              paragraphs={article.relationships.field_technical_article}
            />

            <FieldTechnicalArticle
              type="basic"
              paragraphs={article.relationships.field_technical_article_2}
            />
          </OurProcessWrapper>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default OurProcess;

export const query = graphql`
  query($slug: String!) {
    nodeOurProcessOverview(path: { alias: { eq: $slug } }) {
      ...getOurProcessData
    }
  }
`;

const PageTopCustom = styled.div`
  .heading {
    transition: 0.2s ease-in-out;
    display: block;
    margin-bottom: 0;
    color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
    font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
  }

  @media (min-width: 375px) {
    .heading {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "title"
  })};
    }
  }

  @media (min-width: 480px) {
    .heading {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
    }
  }

  @media (min-width: 568px) {
    .heading {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
    }
  }

  @media (min-width: 600px) {
    .heading {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
    }
  }

  @media (min-width: 768px) {
    .heading {
      max-width: 92rem;
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
    }
  }

  @media (min-width: 960px) {
    .heading {
      padding-bottom: 21%;
    }
  }
`;

const OurProcessWrapper = styled.div``;
